import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { Link, StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Nav from "./Nav";
import Test from "./Test";

import styles from "./Layout.module.css";

import { Navigation, PostCard } from ".";
import config from "../../utils/siteConfig";

// Styles
import "../../styles/app.css";

/**
 * Main layout component
 *
 * The Layout component wraps around each page and template.
 * It also provides the header, footer as well as the main
 * styles, and meta data for each page.
 *
 */
const DefaultLayout = ({ data, children, bodyClass, isHome, isPage }) => {
    const posts = data.allGhostPost.edges;
    const industryPosts = [];
    const productPosts = [];
    console.log(posts);
    posts.forEach((post) => {
        let product = false;
        post.node.tags.forEach((tag) => {
            if (tag.slug === "product-demo") {
                product = true;
            }
        });
        if (product === true) {
            productPosts.push(post);
        } else {
            industryPosts.push(post);
        }
    });
    const site = data.allGhostSettings.edges[0].node;
    const twitterUrl = site.twitter
        ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}`
        : null;
    const facebookUrl = site.facebook
        ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}`
        : null;

    return (
        <>
            <Helmet>
                <html lang={site.lang} />
                <style type="text/css">{`${site.codeinjection_styles}`}</style>
                <body className={bodyClass} />
            </Helmet>

            <div className="viewport">
                <div className="viewport-top">
                    <div>
                        <Test
                            industryPosts={industryPosts}
                            productPosts={productPosts}
                        />
                        {!isHome && (
                            <Link to="/">
                                <h1 className={styles.staticPageHeader}>
                                    <img
                                        className={styles.worldLogo}
                                        src="/assets/images/yoti-logo.png"
                                    ></img>
                                    <span className={styles.worldHeader}>
                                        World
                                    </span>
                                </h1>
                            </Link>
                        )}
                    </div>
                    {/* The main header section on top of the screen */}
                    <header className="site-head">
                        <div className="container">
                            {isHome && (
                                <div className="site-banner">
                                    {/* <h1 className="site-banner-title">{site.title}</h1> */}
                                    <h1 className="site-banner-title">
                                        <img
                                            src="/assets/images/yoti-logo.png"
                                            style={{
                                                height: 59,
                                                width: 128,
                                                marginRight: 20,
                                                marginLeft: 20,
                                                marginBottom: 10,
                                            }}
                                        ></img>
                                        World
                                    </h1>
                                    <p className="site-banner-desc">
                                        {site.description}
                                    </p>
                                    <p className="site-banner-sub-desc">
                                        Discover ways people can use our
                                        products.
                                    </p>
                                    <img
                                        className={styles.icoArrowDown}
                                        src="/assets/images/icons/ico-arrow-down.png"
                                        alt="Arrow Down"
                                    ></img>
                                </div>
                            )}
                        </div>
                    </header>

                    <main
                        className="site-main"
                        style={isHome && { paddingTop: "2vw" }}
                    >
                        {/* All the main content gets inserted here, index.js, post.js */}
                        {children}
                        {!isPage && (
                            <div className="container">
                                <div className={styles.sectionHeader}>
                                    Yoti Solution Demos
                                </div>
                                <section className="post-feed">
                                    {productPosts.map(({ node }) => (
                                        // The tag below includes the markup for each post - components/common/PostCard.js
                                        <PostCard key={node.id} post={node} />
                                    ))}
                                </section>

                                <div className={styles.sectionHeader}>
                                    UK Digital ID Connect Demos
                                </div>
                                <section className="post-feed">
                                    <PostCard
                                        post={{
                                            slug:
                                                "https://yoti.world/digital-id-connect",
                                            excerpt:
                                                "The choice of Yoti ID app, Post Office EasyID app and Lloyds Bank Smart ID app gives your customers a convenient way to verify themselves with your business.",
                                            feature_image: null,
                                            featured: false,
                                            primary_author: null,
                                            title: "Digital ID",
                                            tags: [
                                                {
                                                    feature_image:
                                                        "https://yoti.world/api/content/images/2020/02/IDV.png",
                                                },
                                            ],
                                            primary_tag: {},
                                        }}
                                    />
                                    <PostCard
                                        post={{
                                            slug:
                                                "https://yoti.world/dynamicqr-connect/",
                                            excerpt:
                                                "Supports the creation of QR codes that include dynamically generated attributes.",
                                            feature_image: null,
                                            featured: false,
                                            primary_author: null,
                                            title: "Dynamic QR Code",
                                            tags: [
                                                {
                                                    feature_image:
                                                        "https://yoti.world/api/content/images/2020/02/IDV.png",
                                                },
                                            ],
                                            primary_tag: {},
                                        }}
                                    />
                                </section>

                                <div className={styles.sectionHeader}>
                                    Industry Demos
                                </div>
                                <section className="post-feed">
                                    {industryPosts.map(({ node }) => (
                                        // The tag below includes the markup for each post - components/common/PostCard.js
                                        <PostCard key={node.id} post={node} />
                                    ))}
                                </section>
                            </div>
                        )}
                    </main>
                </div>

                <div className="viewport-bottom">
                    {/* The footer at the very bottom of the screen */}
                    <footer className="site-foot">
                        <div className="site-foot-nav container">
                            <div className="site-foot-nav-left">
                                <Link to="/">{site.title}</Link>
                            </div>
                            <div className="site-foot-nav-right">
                                <Link to="/privacy-policy">Privacy Policy</Link>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </>
    );
};

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    isPage: PropTypes.bool,
    data: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
};

const DefaultLayoutSettingsQuery = (props) => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: { eq: "ghost-icon.png" }) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                allGhostPost(sort: { order: DESC, fields: published_at }) {
                    edges {
                        node {
                            ...GhostPostFields
                        }
                    }
                }
            }
        `}
        render={(data) => <DefaultLayout data={data} {...props} />}
    />
);

export default DefaultLayoutSettingsQuery;
