import React, { useState } from "react";

import NavItems from "./NavItems.js";

import styles from "./Nav.module.css";

import { Link, StaticQuery, graphql } from "gatsby";

const Test = ({ industryPosts, productPosts }) => {
    const [sidebar, setSidebar] = useState(false);

    const toggleSidebar = () => {
        setSidebar((prevState) => !prevState);
    };

    const usefulLinks = [
        {
            id: 1,
            text: "Yoti for business",
            url: "https://www.yoti.com/business/",
            newWindow: true,
        },
        {
            id: 2,
            text: "About Yoti",
            url: "https://www.yoti.com/about/",
            newWindow: true,
        },
        {
            id: 3,
            text: "Developer documentation",
            url: "https://developers.yoti.com",
            newWindow: true,
        },
        {
            id: 4,
            text: "Yoti FAQs",
            url: "https://support.yoti.com/yotisupport/s/BusinessArticleCategories",
            newWindow: true,
        },
    ];

    {
        /* TODO REMOVE HARD CODING */
    }
    const REMOVE_THESE_EVENTUALLY = [
        {
            id: 1,
            text: "Digital ID",
            url: "https://yoti.world/digital-id-connect/",
            newWindow: true,
        },
        {
            id: 2,
            text: "Dynamic QR Code",
            url: "https://yoti.world/dynamicqr-connect/",
            newWindow: true,
        },
    ];

    const industryArray = industryPosts.map(({ node }, index) => {
        const url = `/${node.slug}/`;
        return { id: index, text: node.title, url };
    });

    const productArray = productPosts.map(({ node }, index) => {
        const url = `/${node.slug}/`;
        return { id: index, text: node.title, url };
    });

    return (
        <>
            <Hamburger toggleSidebar={toggleSidebar} />
            {sidebar && (
                <>
                    <div className={styles.sidebar}>
                        <Hamburger
                            className={styles.hamburgerOpen}
                            toggleSidebar={toggleSidebar}
                        />
                        <h3 className={styles.yotiWorld}>
                            <Link style={{ color: "inherit" }} to="/">
                                Yoti World
                            </Link>
                        </h3>
                        <strong className={styles.navHeading}>
                            Yoti Solution Demos
                        </strong>
                        <NavItems items={productArray} />

                        {/* TODO REMOVE HARD CODING */}
                        <strong className={styles.navHeading}>
                            UK Digital ID Connect Demos
                        </strong>
                        <NavItems items={REMOVE_THESE_EVENTUALLY} />

                        <strong className={styles.navHeading}>
                            Industry Demos
                        </strong>
                        <NavItems items={industryArray} />
                        <strong className={styles.navHeading}>
                            Useful links
                        </strong>
                        <NavItems items={usefulLinks} />
                    </div>
                </>
            )}
        </>
    );
};

const Hamburger = ({ toggleSidebar, className = false }) => {
    return (
        <img
            className={className || styles.hamburger}
            onClick={toggleSidebar}
            src="/assets/images/icons/ico-nav.svg"
            alt="Navigation Hamburger"
        ></img>
    );
};

export default Test;
