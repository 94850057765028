import React from "react";

import styles from "./Nav.module.css";

const Nav = ({ items }) => {
    return (
        <ul className={styles.navList}>
            {items.map(item => (
                <a key={item.id} target={item.newWindow && "_blank"} href={item.url}>
                    <li className={styles.navItem}>{item.text}</li>
                </a>
            ))}
        </ul>
    );
};

export default Nav;
