import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const PostCard = ({ post }) => {
    const url = `/${post.slug}/`;

    const active =
        typeof window !== `undefined`
            ? window.location.pathname === url
            : false;

    const onClickHandler = (event) => {
        active && event.preventDefault();
    };
    let productDemo = false;
    post.tags.forEach((tag) => {
        if (tag.slug === "product-demo") {
            productDemo = true;
        }
    });

    const sortedTags =
        post.tags && post.tags.sort((a, b) => (a.name < b.name ? -1 : 1));

    // Open in new tab if post slug contains https://
    return post.slug.includes("https") ? (
        <a
            target="_blank"
            className={"post-card" + (active ? " post-card-active" : "")}
            href={post.slug}
        >
            <CardContent
                post={post}
                productDemo={productDemo}
                sortedTags={sortedTags}
            />
        </a>
    ) : (
        <Link
            onClick={onClickHandler}
            to={url}
            className={"post-card" + (active ? " post-card-active" : "")}
        >
            <CardContent
                post={post}
                productDemo={productDemo}
                sortedTags={sortedTags}
            />
        </Link>
    );
};

const CardContent = ({ post, productDemo, sortedTags }) => (
    <>
        <header className="post-card-header">
            <h2 className="post-card-title">
                {post.title}
                <span
                    className={`post-card-right-chevron ${
                        productDemo ? "product-chevron" : ""
                    }`}
                >
                    <img
                        src="/assets/images/icons/ico-chevron-right.svg"
                        alt="right chevron"
                    />
                </span>
            </h2>
            {post.tags && (
                <div className="post-card-tags">
                    {post.primary_tag.meta_title}
                </div>
            )}
            {post.featured && <span>Featured</span>}
        </header>
        <section className="post-card-excerpt">{post.excerpt}</section>
        <footer className="post-card-footer">
            <div className="post-card-footer-left">
                {sortedTags &&
                    sortedTags.map(
                        (tag) =>
                            tag.feature_image && (
                                <img
                                    key={tag.name}
                                    className="post-card-tag-icon"
                                    src={tag.feature_image}
                                    alt={tag.name}
                                />
                            )
                    )}
            </div>
            <div className="post-card-footer-right">
                <span>
                    <img
                        src="/assets/images/icons/ico-chevron-right.svg"
                        alt="right chevron"
                    />
                </span>
            </div>
        </footer>
    </>
);

PostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
};

export default PostCard;
